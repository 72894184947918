// File: src/pages/inicio.js

import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/inicio.module.css';


function Inicio() {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>
        Dashboard
      </h1>
    </div>
  );
}

export default Inicio;
