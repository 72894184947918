import React from 'react';
import styles from '../styles/empresa.module.css';

function EmpleadoForm({ empleadoData, handleEmpleadoChange, handleSaveEmpleado, roles, showModal, setShowModal, editEmpleadoId }) {
    return (
        showModal && (
            <div className={styles.modal}>
                <div className={styles.modalContent}>
                    <h2>{editEmpleadoId ? "Editar Empleado" : "Añadir Empleado"}</h2>

                    <div className={styles.formRow}>
                        <input
                            type="text"
                            name="nombre"
                            placeholder="Nombre"
                            value={empleadoData.nombre}
                            onChange={handleEmpleadoChange}
                        />
                        <input
                            type="text"
                            name="apellido"
                            placeholder="Apellido"
                            value={empleadoData.apellido}
                            onChange={handleEmpleadoChange}
                        />
                    </div>

                    <div className={styles.formRow}>
                        <input
                            type="text"
                            name="dni"
                            placeholder="DNI"
                            value={empleadoData.dni}
                            onChange={handleEmpleadoChange}
                        />
                        <div className={styles.inputSelect}>
                            <label htmlFor="genero">Género</label>
                            <select
                                name="genero"
                                value={empleadoData.genero}
                                onChange={handleEmpleadoChange}
                                className={styles.selectGenero}
                            >
                                <option value="">Seleccione un género</option>
                                <option value="masculino">Masculino</option>
                                <option value="femenino">Femenino</option>
                            </select>
                        </div>
                    </div>

                    <div className={styles.formRow}>
                        <input
                            type="date"
                            name="fecha_nacimiento"
                            placeholder="Fecha de nacimiento"
                            value={empleadoData.fecha_nacimiento}
                            onChange={handleEmpleadoChange}
                        />
                        <input
                            type="date"
                            name="fecha_inicio_empresa"
                            placeholder="Fecha de inicio en la empresa"
                            value={empleadoData.fecha_inicio_empresa}
                            onChange={handleEmpleadoChange}
                        />
                    </div>

                    <div className={styles.formRow}>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={empleadoData.email}
                            onChange={handleEmpleadoChange}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Contraseña"
                            value={empleadoData.password}
                            onChange={handleEmpleadoChange}
                        />
                    </div>

                    <div className={styles.formRow}>
                        <div className={styles.inputSelect}>
                            <label htmlFor="id_rol">Rol</label>
                            <select
                                name="id_rol"
                                value={empleadoData.id_rol}
                                onChange={handleEmpleadoChange}
                                className={styles.selectGenero}
                            >
                                <option value="">Seleccione un rol</option>
                                {roles.map((rol) => (
                                    <option key={rol.id_rol} value={rol.id_rol}>
                                        {rol.rol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* Poner un checkbox para marcar como administrador de canal ético */}
                        <div className={styles.inputcheckbox}>
                            <input
                                type="checkbox"
                                name="admin_canal_etico"
                                checked={empleadoData.admin_canal_etico}
                                onChange={handleEmpleadoChange}
                                className={styles.checkbox}
                            />
                            <label htmlFor="admin_canal_etico">Admin Canal Ético</label>
                        </div>
                    </div>

                    <div className={styles.formRow}>
                        <button onClick={handleSaveEmpleado}>
                            {editEmpleadoId ? "Guardar Cambios" : "Agregar Empleado"}
                        </button>
                        <button onClick={() => setShowModal(false)} style={{ marginLeft: '10px' }}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        )
    );
}

export default EmpleadoForm;
